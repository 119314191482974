/**
 * Created by Tom L. on 05.03.2021.
 */
module gmtApp.gamification {
    const moduleName = 'gmtApp.gamification';
    angular.module(moduleName, [
        'gmtApp.database',
        'gmtApp.utilities',
        'gmtApp.toggleSwitch',
        'gmtApp.imageThumbnailChooser',
        'ngRoute',
        'ui.bootstrap'
    ]);
}
